







































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBaseButton, DefaTransitionFadeOutIn } from "@defa-as/components";
import UsernameInput from "@/components/inputs/UsernameInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import PasswordConfirmInput from "@/components/inputs/PasswordConfirmInput.vue";
import { loadingModule } from "@/store/modules/loading-module";
import { userModule } from "@/store/modules/user-module";

@Component({
  components: {
    DefaTransitionFadeOutIn,
    DefaBaseButton,
    ValidationObserver,
    UsernameInput,
    PasswordInput,
    PasswordConfirmInput,
  },
})
export default class ChangePasswordForm extends Vue {
  model = {
    password: "",
    passwordConfirm: "",
  };

  get loadingModule() {
    return loadingModule;
  }

  get userModule() {
    return userModule;
  }

  async changePassword(resetValidationState: () => void) {
    await this.userModule.changePassword({ password: this.model.password });
    if (this.userModule.isPasswordChangeSuccessful) {
      this.model = {
        password: "",
        passwordConfirm: "",
      };
      resetValidationState();
    }
  }
}
