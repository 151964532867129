



















import { Component, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import ChangePasswordForm from "@/components/forms/ChangePasswordForm.vue";
import { userModule } from "@/store/modules/user-module";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    ChangePasswordForm,
    DefaBaseButton,
  },
})
export default class ViewProfile extends Vue {
  get userModule() {
    return userModule;
  }

  get roleTranslated() {
    return this.$t(`profile.role.${userModule.role}`);
  }

  async logout() {
    await this.userModule.logout();
    await this.$router.replace({
      name: ROUTE_NAMES.LOGIN,
    });
  }
}
