












import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";

@Component({
  components: {
    DefaBaseInput,
  },
})
export default class PasswordConfirmInput extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ required: true }) readonly target!: string;
  @Prop({ default: "passwordConfirmInput.label" }) readonly labelKey!: string;

  get confirmedValidationMessage() {
    return {
      confirmed: this.$t("shared.veeValidate.customMessages.passwordConfirm"),
    };
  }

  get rules() {
    return `required|confirmed:${this.target}`;
  }

  get passwordConfirm() {
    return this.value;
  }

  set passwordConfirm(passwordConfirm: string) {
    this.$emit("input", passwordConfirm);
  }
}
