












import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import type { Nullable, Rules } from "@defa-as/utils";

@Component({
  components: { DefaBaseInput },
})
export default class UsernameInput extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: "usernameInput.label" }) readonly labelKey!: string;
  @Prop({ default: false }) readonly autofocus!: boolean;
  @Prop({ default: "username" }) readonly name!: string;
  @Prop({ default: null }) readonly rules!: Nullable<Rules>;

  get username() {
    return this.value;
  }

  set username(value: string) {
    this.$emit("input", value);
  }
}
