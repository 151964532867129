











import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import type { Rules } from "@defa-as/utils";

type PasswordType = "passwordNew" | "passwordCurrent";

@Component({
  components: {
    DefaBaseInput,
  },
})
export default class PasswordInput extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ required: true }) readonly passwordType!: PasswordType;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: null }) readonly rules!: Rules;

  get autocomplete() {
    if (this.passwordType === "passwordCurrent") {
      return "current-password";
    }
    return "new-password";
  }

  get labelOrDefault() {
    return this.label || this.$t(`passwordInput.${this.passwordType}.label`);
  }

  get password() {
    return this.value;
  }

  set password(password: string) {
    this.$emit("input", password);
  }
}
